import {
  Image,
  createStyles,
  Header,
  Container,
  Group,
  Button,
  Avatar,
  ActionIcon,
  Menu,
  Text,
  Divider,
  Burger,
} from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";

import {
  Logout,
  User,
  ChevronDown,
  ArrowNarrowLeft,
  IdBadge2,
} from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import { useFirebaseAuth } from "../../../hooks/firebaseAuth.hook";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { useUser } from "../../../hooks/user.hook";
import { useRoles } from "../../../hooks/roles.hooks";
import Role from "../../../enums/role.enum";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { IRaiseHandStateData } from "../../../store/reducers/raise-hand.reducer";
import { RiLockPasswordLine } from "react-icons/ri";
import { BsQuestion } from "react-icons/bs";
const HEADER_HEIGHT = 60;
type Props = {
  eventName?: any;
  setOpenQuestionModal: any;
  navigatePage?: any;
  orgaId?: string;
  eventRole?: any;
};
const Index = (props: Props) => {
  const { height, width } = useViewportSize();

  let { id }: any = useParams();
  const { t, i18n } = useTranslation("common");
  const { classes } = useStyles();
  const { handlers: rolesHandlers, OrganizationRole } = useRoles();

  const { eventName } = props;
  const [opened, { toggle }] = useDisclosure(false);
  const { handlers, firebaseUserInfo, profile } = useUser();

  const { logout } = useFirebaseAuth();
  let location: any = useLocation();
  let navigate = useNavigate();

  const raiseHandState: IRaiseHandStateData = useSelector(
    (state: RootState) => state.raiseHand.currentRaiseHandState
  );

  const page = [
    {
      title: "Events",
      link: `/organizations/${id}/events`,
    },
    {
      title: "Members",
      link: `/organizations/${id}/members`,
    },
  ];
  const items = () => {
    return page.map((x: any, i: number) => (
      <a key={i} href={x.link} className={`${classes.link} `}>
        <Text weight={x.link == location.pathname ? 800 : 500}>
          {t(`layout.${x.title}`)}
        </Text>
        <div
          className={`${x.link == location.pathname && classes.activeLink}`}
        ></div>
      </a>
    ));
  };
  const interpreterMenu = () => {
    return (
      <a href={page[0].link} className={`${classes.link} `}>
        <Text weight={page[0].link == location.pathname ? 800 : 500}>
          {t(`layout.${page[0].title}`)}
        </Text>
        <div
          className={`${
            page[0].link == location.pathname && classes.activeLink
          }`}
        ></div>
      </a>
    );
  };
  const goBack = () => {
    if (location.pathname == `/organizations/${id}/new-event`) {
      navigate(`/organizations/${id}/events`);
    } else if (location.pathname == `/organizations/${id}/members`) {
      navigate("/organizations");
    }  else {
      navigate(props.navigatePage ? props.navigatePage : -1);
    }
  };
  return (
    <Header
      height={HEADER_HEIGHT}
      style={{
        overflow: opened ? "inherit" : "hidden",
        position: opened ? "inherit" : "relative",
      }}
      className={classes.header}
    >
      <Container className={classes.inner} fluid>
        <Group className={classes.logo_area}>
          {location.pathname != "/organizations" ||
            (location.pathname != `/event/${id}` && (
              <ActionIcon onClick={goBack}>
                <ArrowNarrowLeft />
              </ActionIcon>
            ))}
          {location.pathname == `/event/${id}` && (
            <a href={`/organizations/${props.orgaId}/events`}>
              <ArrowNarrowLeft color="black" />
            </a>
          )}
          {(width > 460 || (raiseHandState && !raiseHandState.raised)) && (
            <a href="/organizations">
              <Image
                className={classes.logo}
                height={60}
                fit="contain"
                src={require("../../../assets/logo.png")}
              />
            </a>
          )}
        </Group>
        <>
          {location.pathname == "/organizations" ? (
            ""
          ) : location.pathname != `/organizations/${id}/events` &&
            location.pathname != `/organizations/${id}/members` ? (
            (width > 1200 || (raiseHandState && !raiseHandState.raised)) && (
              <Group spacing={10} className={classes.eventName}>
                <Text>{eventName}</Text>
              </Group>
            )
          ) : (
            <Group spacing={5} className={classes.links}>
              {OrganizationRole == Role.Interpreter ||
              OrganizationRole == Role.User ||
              OrganizationRole == Role.Customer
                ? interpreterMenu()
                : items()}
            </Group>
          )}
          <Group className={classes.profile_area}>
            {raiseHandState &&
              raiseHandState.raised &&
              props.eventRole != Role.Customer &&
              location.pathname == `/event/${id}` && (
                <div className={classes.raise_hand_container}>
                  <div className={classes.raise_hand}>
                    <span>✋</span>
                    <Text className={classes.raisByName} color="white">
                      {raiseHandState.raisedBy}
                    </Text>
                  </div>
                </div>
              )}
            <Menu
              className={classes.profile}
              control={
                <Button color="dark" variant="subtle">
                  <Avatar radius="xl" />
                  <Text
                    className={classes.profileName}
                    style={{ fontSize: 17 }}
                  >
                    {profile?.fullName}
                  </Text>
                  <ChevronDown />
                </Button>
              }
            >
              <Menu.Item
                onClick={() => navigate("/profile")}
                icon={<User size={16} />}
              >
                <Text style={{ fontSize: 17 }}>{t("profile")}</Text>
              </Menu.Item>
              <Menu.Item
                onClick={() => props.setOpenQuestionModal(true)}
                icon={<BsQuestion size={18} />}
              >
                <Text style={{ fontSize: 17 }}>{t("support")}</Text>
              </Menu.Item>
              <Menu.Item
                onClick={() => navigate("/edit-password")}
                icon={<RiLockPasswordLine size={16} />}
              >
                <Text style={{ fontSize: 17 }}>{t("change_password")}</Text>
              </Menu.Item>
              <Divider />
              <Menu.Item
                onClick={() => logout()}
                color="red"
                icon={<Logout size={14} />}
              >
                <Text style={{ fontSize: 17 }}>{t("logout")}</Text>
              </Menu.Item>
            </Menu>
          </Group>
        </>
        <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
        />
      </Container>
      {opened && (
        <div className={classes.mobile_menu}>
          <Group spacing={5} className={classes.mobile_links}>
            {location.pathname == `/organizations/${id}/events` ||
            location.pathname == `/organizations/${id}/members`
              ? OrganizationRole == Role.Interpreter ||
                OrganizationRole == Role.User
                ? interpreterMenu()
                : items()
              : ""}
            <a href="/profile" className={`${classes.link} `}>
              <Text weight={location.pathname == "/profile" ? 800 : 500}>
                {t(`profile`)}
              </Text>
              <div
                className={`${
                  location.pathname == "/profile" && classes.activeLink
                }`}
              ></div>
            </a>
            <a onClick={() => logout()} className={`${classes.link} `}>
              <Logout color="red" size={14} />
              <Text
                color="red"
                ml="xs"
                weight={location.pathname == "/profile" ? 800 : 500}
              >
                {t(`logout`)}
              </Text>
            </a>
          </Group>
        </div>
      )}
    </Header>
  );
};
const useStyles = createStyles((theme) => ({
  mobile_links: {
    flexDirection: "column",
  },

  request_alert: {
    width: 20,
    height: 20,
    borderRadius: "50%",
  },
  request_alert_connect: {
    backgroundColor: theme.colors.green[5],
  },
  raise_hand_container: {
    position: "relative",
    overflow: "hidden",
  },
  raise_hand: {
    // position: "absolute",
    width: 150,
    backgroundColor: theme.colors.green[5],
    padding: "10px 20px",
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    // animation: "mymove 2s  both ",
    animation: "slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
    "@keyframes slide-left": {
      "0%": {
        transform: "translateX(100px)",
      },
      "100%": {
        transform: "translateX(0px)",
      },
      "@media (max-width: 768px)": {},
    },
    span: {
      paddingRight: 10,
    },
  },
  request_alert_disconnect: {
    backgroundColor: theme.colors.gray[5],
  },
  mobile_menu: {
    backgroundColor: theme.colors.gray[2],
    width: "100%",
    height: "calc(100vh - 120px)",
    zIndex: 9999,
    position: "absolute",
  },
  logo: {
    width: 200,
    "@media (max-width: 768px)": {
      width: 150,
    },
  },
  inner: {
    height: "HEADER_HEIGHT",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  header: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },
  profileName: {
    marginLeft: 10,
    maxWidth: 150,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  raisByName: {
    marginLeft: 0,
    maxWidth: 300,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  profile: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
  profile_area: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.fn.smallerThan("md")]: {
      width: "auto",
      marginRight: 10,
    },
  },
  logo_area: {
    height: 60,
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  links: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
  eventName: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
  burger: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },

  link: {
    height: 60,
    display: "flex",
    alignItems: "center",
    position: "relative",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  linkLabel: {
    marginRight: 5,
  },
  activeLink: {
    height: 3,
    borderRadius: 10,
    backgroundColor: "lightgray",
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
  },
}));
export default Index;
