import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Layout from "../../components/low-level/layout";
import { useEvents } from "../../hooks/event.hook";
import useAgora from "../../hooks/agora.hooks";
import AgoraPlayer from "../../components/low-level/AgoraPlayer/AgoraPlayer";
import InterpreterArea from "../../components/low-level/interpreter-area/InterpreterArea";
import useChat, { IMessage } from "../../hooks/chat.hooks";
import { userOnlineOffline } from "../../hooks/user-online-offline.hook";
import { useUser } from "../../hooks/user.hook";
import Role from "../../enums/role.enum";
import { useLocalStorage } from "@mantine/hooks";

const EventPage: React.FunctionComponent = (props) => {
  // var userStatusDatabaseRef = database().ref("/status/" + uid);

  const { handlers, firebaseUserInfo, profile } = useUser();
  const [fixed, setFixed] = useLocalStorage({
    key: "fixedMessageBox",
    defaultValue: false,
  });
  const [opened, setOpened] = useLocalStorage({
    key: "openMessageBox",
    defaultValue: false,
  });
  var location = useLocation();
  useEffect(() => {
    userOnlineOffline();
  }, [location.pathname]);

  const { id }: any = useParams();
  const {
    handlers: eventsHandlers,
    events,
    currentEvent,
    loading,
  } = useEvents();

  console.log("zxccurrentEvent",currentEvent)
  const { initChat, sendMessage, messages }: any = useChat();
  const [openEditArea, setOpenEditArea] = useState(false);

  const {
    localAudioTrack,
    // localVideoTrack,
    leave,
    join,
    init,
    joinState,
    remoteUsers,
    remoteUsersNames,
    connecting,
    clientInitialized,
  }: any = useAgora(profile);

  useEffect(() => {
    if (id) {
      eventsHandlers.getEventData(id);
    }
    return () => {
      eventsHandlers.resetEventData();
    };
  }, [id]);

  useEffect(() => {
    if (!currentEvent) {
      return;
    }

    if (currentEvent.isAudioStreaming) {
      init();
    }
  }, [currentEvent]);

  useEffect(() => {
    if (!clientInitialized) {
      return;
    }
    return;
    handleJoin();
  }, [clientInitialized]);

  const handleJoin = () => {
    if (!currentEvent) return;
    join(currentEvent.uid, "audience");
  };

  const handleJoinAsHost = () => {
    if (!currentEvent) return;
    join(currentEvent.uid, "host");
  };

  return (
    <Layout
      eventName={currentEvent?.name}
      eventRole={currentEvent?.usersRole}
      showMessage={true}
      scrollScreen={true}
      event={currentEvent}
      fixed={fixed}
      setFixed={setFixed}
      orgaId={currentEvent?.organization?.id}
      opened={opened}
      setOpened={setOpened}
      audioJoinState={joinState}
      initChat={initChat}
      remoteUsersNames={remoteUsersNames}
      sendMessage={sendMessage}
      setOpenEditArea={setOpenEditArea}
      messages={messages}
    >
      <InterpreterArea
        event={currentEvent}
        setOpened={setOpened}
        setFixed={setFixed}
        setOpenEditArea={setOpenEditArea}
        openEditArea={openEditArea}
        audioJoinState={joinState}
        handleJoin={handleJoin}
        handleJoinAsHost={handleJoinAsHost}
        leave={leave}
        audioConnecting={connecting}
      />
      {currentEvent?.isAudioStreaming &&
        currentEvent?.usersRole !== Role.Customer &&
        remoteUsers &&
        remoteUsers.map((user: any, index: number) => (
          <AgoraPlayer key={index} audioTrack={user.audioTrack}></AgoraPlayer>
        ))}
    </Layout>
  );
};

export default EventPage;

// lila dumm koopf papa
