import React, { useState, useEffect } from "react";
import { createStyles } from "@mantine/core";
import Header from "../header";
import Footer from "../footer";
import ILayout from "../../../interfaces/layout.interface";
import { useLocation } from "react-router-dom";
import MessageBox from "../message/messageBox";
import { useRoles } from "../../../hooks/roles.hooks";
import { useParams } from "react-router-dom";
import Loading from "../loading";
import SupportMailBox from "../supportMailBox/supportMailBox";
import { ReactInternetSpeedMeter } from "../internet-speed-tester";
import AlertDialog from "../dialog/AlertDialog";
import { useTranslation } from "react-i18next";

const Layout: React.FunctionComponent<ILayout> = (props: any) => {
  const { id }: any = useParams();
  const { t } = useTranslation("common");
  const {
    event,
    fixed,
    opened,
    setFixed,
    setOpened,
    remoteUsersNames,
    setOpenEditArea,
    orgaId,
    audioJoinState,
    eventRole,
  } = props;
  const [ethernetSpeed, setEthernetSpeed] = useState(null);

  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [offline, setOffline] = useState(false);
  const { classes } = useStyles();

  const { handlers: rolesHandlers, loading } = useRoles();

  let location = useLocation();

  useEffect(() => {
    if (
      location.pathname == `/organizations/${id}/events` ||
      (location.pathname == `/organizations/${id}/members` && id)
    ) {
      rolesHandlers.getRoles(id);
    }
  }, [id, location.pathname]);
  const openDrawer = () => {
    setFixed(false);
    if (!fixed && opened) setOpened(false);
    if (!opened) setOpened(true);
    if (fixed && !opened) setOpened(false);
  };

  const fixedMessageArea = () => {
    if (!fixed) {
      setOpened(false);
      setFixed(true);
    } else {
      setOpened(true);
      setFixed(false);
    }
  };

  const ethernetControl = () => {
    if (!offline && ethernetSpeed && ethernetSpeed < 5) {
      return (
        <AlertDialog
          text={t("layout.internet_bad")}
          open={true}
          success={false}
        />
      );
    }
    if (offline) {
      return (
        <AlertDialog
          text={t("layout.internet_control_please")}
          open={true}
          success={false}
        />
      );
    }
  };
  if (loading) return <Loading />;
  return (
    <div className={classes.layout_main}>
      <div
        className={
          location.pathname == "/event/2" && fixed
            ? classes.layout_main_content
            : classes.layout_main_big
        }
      >
        <SupportMailBox
          openModal={openQuestionModal}
          setOpenModal={setOpenQuestionModal}
          closeMail={false}
        />
        <Header
          eventRole={eventRole}
          setOpenQuestionModal={setOpenQuestionModal}
          eventName={props.eventName}
          orgaId={orgaId}
          navigatePage={props?.navigate}
        />

        <div
          className={classes.layout}
          style={{
            height: props.scrollScreen && "auto",
            maxHeight: props.scrollScreen && "100%",
            minHeight: props.scrollScreen && "calc(100vh - 131px)",
          }}
        >
          {props.children}
        </div>
        <Footer
          showMessage={props.showMessage}
          setOpenEditArea={setOpenEditArea}
          opened={opened}
          openDrawer={openDrawer}
          setOpenQuestionModal={setOpenQuestionModal}
          fixDrawer={fixedMessageArea}
          fixed={props.fixed}
          event={event}
          audioJoinState={audioJoinState}
          initChat={props.initChat}
          remoteUsersNames={remoteUsersNames}
          sendMessage={props.sendMessage}
          ethernetSpeed={ethernetSpeed}
          messages={props.messages}
        />
        {ethernetControl()}
      </div>
      {location.pathname.includes("event") && (
        <ReactInternetSpeedMeter
          txtSubHeading="Internet is too slow"
          outputType="alert"
          customClassName={null}
          txtMainHeading="Opps..."
          pingInterval={4000} // milliseconds
          thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
          threshold={100}
          imageUrl="https://firebasestorage.googleapis.com/v0/b/interpreteri.appspot.com/o/ethernet-speed-test%2Ficon.png?alt=media&token=be545916-3c1c-440a-b69d-9df977a1921b"
          downloadSize="1781287" //bytes
          callbackFunctionOnNetworkTest={(speed: any) => {
            setOffline(false);
            setEthernetSpeed(speed);
          }}
          callbackFunctionOnError={(error: any) => setOffline(true)}
        />
      )}

      {props.showMessage && fixed && event && (
        <div className={classes.message_area}>
          <MessageBox
            initChat={props.initChat}
            sendMessage={props.sendMessage}
            messages={props.messages}
            ethernetSpeed={ethernetSpeed}
            fixed={fixed}
            offline={offline}
            audioJoinState={audioJoinState}
            remoteUsersNames={remoteUsersNames}
            openDrawer={openDrawer}
            setOpenQuestionModal={setOpenQuestionModal}
            fixDrawer={fixedMessageArea}
            event={event}
          />
        </div>
      )}
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  layout_main: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  layout_main_content: {
    width: "75%",
    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
      width: "80%",
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: "70%",
    },
  },
  layout_main_big: {
    width: "100%",
  },
  message_area: {
    width: "26.6%",
    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
      width: "42.9%",
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: "66.7%",
    },
  },
  layout: {
    maxHeight: "calc(100vh - 131px)",
    height: "calc(100vh - 131px)",
    backgroundColor: theme.colors.gray[1],
    "@media (max-width: 769px)": {
      maxHeight: "calc(100vh - 210px)",
      height: "calc(100vh - 210px)",
    },
  },
}));
export default Layout;
