import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { NotificationsProvider } from "@mantine/notifications";
import { MantineProvider, Loader } from "@mantine/core";

import ProtectedRoute from "./modules/auth/ProtectedRoute";
// import OuterLayout from "./Layout/OuterLayout";
// import InnerLayout from "./Layout/InnerLayout";

import routes from "./config/routes";

import { useSelector } from "react-redux";
import { selectIsDarkTheme } from "./store/reducers/ui.reducer";
import { useFirebaseAuth } from "./hooks/firebaseAuth.hook";
import { auth, onAuthStateChanged } from "./api/firebase";
import Dialog from "./components/low-level/dialog/AlertDialog";

import "./App.scss";
import SignInPage from "./pages/Auth/SignInPage/SignInPage";
import SignUpPage from "./pages/Auth/SignUpPage/SignUpPage";
import ResetPasswordPage from "./pages/Auth/ResetPasswordPage/ResetPasswordPage";
import EventsPage from "./pages/EventPage/EventsPage";
import EventPage from "./pages/EventPage/EventPage";
import EditEventPage from "./pages/EditEventPage/EditEventPage";
import NewEventPage from "./pages/NewEventPage/NewEventPage";
import OrganizationPage from "./pages/OrganizationPage/OrganizationPage";
import EditOrganizationPage from "./pages/EditOrganizationPage/EditOrganizationPage";
import NewOrganizationPage from "./pages/NewOrganizationPage/NewOrganizationPage";

//Profile
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import EditPasswordPage from "./pages/ProfilePage/EditPasswordPage";
import { useTranslation } from "react-i18next";
import { useUser } from "./hooks/user.hook";
import { AppDispatch } from "./store/store";
import { useAppDispatch } from "./store/hooks";
import { setProfile } from "./store/reducers/user.reducer";

//Member
import MembersPage from "./pages/MembersPage/MembersPage";
import NewMemberPage from "./pages/NewMemberPage/NewMemberPage";
import EditMemberPage from "./pages/EditMemberPage/EditMemberPage";

//Public Event Page
import PublicEventPage from "./pages/PublicEventPage/PublicEventPage";
import JoinEventPage from "./pages/JoinEventPage/JoinEventPage";
import DownloadPage from "./pages/DownloadPage/DownloadPage";
import { setError, setSuccess } from "./store/reducers/notification.reducer";
import EvenPageSignLanguage from "./pages/EventPage/EventPageSignLanguage";
import PublicEventOnlyTextPage from "./pages/PublicEventOnlyTextPage/PublicEventOnlyTextPage";

export interface IApplicationProps {}

const AppRoutes: React.FunctionComponent<IApplicationProps> = (props) => {
  const { logout, handleUserInfo } = useFirebaseAuth();
  const { i18n } = useTranslation();
  const { handlers, firebaseUserInfo, profile } = useUser();
  const dispatch: AppDispatch = useAppDispatch();
  const error = useSelector((state: any) => state.notification.errorMessage);
  const successMessage = useSelector(
    (state: any) => state.notification.successMessage
  );

  const [message, setMessage] = useState(false);

  const [loading, setLoading] = useState(true);

  const isAuth = firebaseUserInfo !== null && profile !== null && !loading;

  useEffect(() => {
    if (error?.length > 0 || successMessage?.length > 0) {
      setMessage(true);
      setTimeout(() => {
        setMessage(false);
        dispatch(setError(""));
        dispatch(setSuccess(""));
      }, 3000);
    }
  }, [error, successMessage]);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async (userAuth) => {
      if (userAuth) {
        handleUserInfo(userAuth);
        const profile: any = await handlers.getProfile(userAuth.uid);
        if (profile) {
          dispatch(setProfile(profile[0]));
        }
        if (!profile && userAuth.email) {
          await handlers.createProfile({
            email: userAuth.email,
            firebaseUid: userAuth.uid,
            firstName: userAuth.email,
            lastName: userAuth.email,
          });
        }
        setLoading(false);
      } else {
        logout();
        setLoading(false);
      }
    });
    return () => {
      unsub();
      setLoading(false);
    };
  }, []);

  if (loading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader color="red" variant="dots" />
      </div>
    );
  }

  return (
    <MantineProvider
      theme={{
        colorScheme: "light",
        fontFamily: "Roboto, sans-serif",
        colors: {
          "la-primary": [
            "#F88273",
            "#F88273",
            "#F88273",
            "#F88273",
            "#F88273",
            "#F88273",
            "#F88273",
            "#F88273",
            "#F88273",
            "#F88273",
          ],
          "la-secondary": [
            "#939393",
            "#939393",
            "#939393",
            "#939393",
            "#939393",
            "#939393",
            "#939393",
            "#939393",
            "#939393",
            "#939393",
          ],
        },
      }}
    >
      <NotificationsProvider
        position="bottom-center"
        limit={1}
        autoClose={1400}
      >
        <Dialog text={error} success={successMessage} open={message} />
        <BrowserRouter>
          <Routes>
            <Route
              element={
                <ProtectedRoute isAllowed={isAuth} redirectPath="/login" />
              }
            >
              <Route path="/organizations" element={<OrganizationPage />} />
              <Route
                path="/new-organization"
                element={<NewOrganizationPage />}
              />
              <Route
                path="/edit-organization"
                element={<EditOrganizationPage />}
              />
              <Route
                path="/organizations/:id/events"
                element={<EventsPage />}
              />
              <Route
                path="/organizations/:id/members"
                element={<MembersPage />}
              />
              <Route
                path="/organizations/:id/:memberId/edit-member"
                element={<EditMemberPage />}
              />
              <Route
                path="/organizations/:id/new-member"
                element={<NewMemberPage />}
              />
              <Route path="/event/:id" element={<EventPage />} />
              {/* <Route path="/event/:id/sign-language" element={<EvenPageSignLanguage />} /> */}
              <Route
                path="/organizations/:id/new-event"
                element={<NewEventPage />}
              />
              <Route
                path="/organizations/:id/:eventId/edit-event"
                element={<EditEventPage />}
              />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/edit-password" element={<EditPasswordPage />} />
            </Route>
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<SignInPage name="Login" />} />
            <Route path="/p/:id" element={<PublicEventPage />} />
            <Route path="/t/:id" element={<PublicEventOnlyTextPage />} />
            <Route path="/join-event" element={<JoinEventPage />} />
            <Route path="/download" element={<DownloadPage />} />
            <Route
              path="/password-reset"
              element={<ResetPasswordPage name="ResetPasswordPage" />}
            />
            <Route
              path="/registration"
              element={<SignUpPage name="Registration" />}
            />
          </Routes>
        </BrowserRouter>
      </NotificationsProvider>
    </MantineProvider>
  );
};

export default AppRoutes;
