import { useState, useEffect, useCallback } from "react";
import { createStyles, Group } from "@mantine/core";
import { useLocalStorage, useHover } from "@mantine/hooks";
import Loading from "../loading";
import { IEvent } from "../../../interfaces/event.interface";
import TextAreaEditor from "./textAreaEditor";
import { useSearchParams } from "react-router-dom";
import { logEventAnalytics } from "../../../api/firebase";
import RichTextEditor from "./richText";
import useSignLanguage from "../../../hooks/sign-language.hooks";
import TranslationReplayArea from "./translationReplayArea";
import OnlyTextTranslationArea from "./onlyTextTranslationArea";

const PublicInterpreterOnlyText = (props: { event: IEvent }) => {
  const { event } = props;
  const [searchParams] = useSearchParams();
  const urlParams = Object.fromEntries([...searchParams]);
  const { ref } = useHover();
  const [mobile, setMobile] = useState(false);
  const { firebasePublicGetData, signLanguageState } = useSignLanguage(
    event?.id
  );
  const [textFullSize, setTextFullSize] = useLocalStorage({
    key: "textFullSize",
    defaultValue: false,
  });
  const [divideIntoTwo, setDivideIntoTwo] = useState(false);
  const [textAreaColor, setTextAreaColor] = useState("dark");
  const [textAreaFontSize, setTextAreaFontSize] = useLocalStorage({
    key: "textAreaFontSize",
    defaultValue: 24,
  });
  const [variation, setVariation]: any = useState(1);
  const [downloadWorld, setDownloadDocx] = useState(false);
  const { classes } = useStyles();
  const [currentTranslation, setCurrentTranslation] = useState<null | string>(
    null
  );
  const [showTranslation, setShowTranslation] = useState(false);
  const [showVideo, setShowVideo]: any = useState(false);

  const colorData = [
    { color: "light" },
    { color: "light_text_background" },
    { color: "green" },
    { color: "green_black" },
    { color: "blue" },
    { color: "blue_black" },
    { color: "transparent_text" },
    { color: "transparent" },
  ];

  const handleSetTranslation = (translation: string) => {
    setShowTranslation(true);
    setCurrentTranslation(translation);
    try {
      logEventAnalytics("changeTranslation", { translation: translation });
      logEventAnalytics(event.shortUid + " -> " + translation, {
        translation: translation,
      });
    } catch (error) {}
  };

  const handleShowSource = () => {
    setShowTranslation(false);
    setCurrentTranslation(null);
  };

  useEffect(() => {
    if (!event || !event.shortUid) return;
    logEventAnalytics(event.shortUid, { event: event?.shortUid });
  }, [event]);

  useEffect(() => {
    if (event && showVideo) {
      firebasePublicGetData(event.id);
    }
  }, [event, showVideo]);
  useEffect(() => {
    if (urlParams?.split != undefined) {
      if (JSON.parse(urlParams?.split?.toLowerCase())) {
        setDivideIntoTwo(true);
        if (
          urlParams?.language === "main" ||
          urlParams?.language == undefined
        ) {
          handleShowSource();
        } else {
          setCurrentTranslation(urlParams?.language);
          setShowTranslation(true);
        }
      } else {
        setDivideIntoTwo(false);
        if (urlParams?.language === "main") {
          handleShowSource();
        } else {
          setCurrentTranslation(urlParams?.language);
          setShowTranslation(true);
        }
      }
    } else {
      if (urlParams?.language != undefined) {
        if (urlParams?.language === "main") {
          handleShowSource();
        } else {
          handleSetTranslation(urlParams?.language);
          setCurrentTranslation(urlParams?.language);
          setShowTranslation(true);
        }
      }
    }
  }, [urlParams, searchParams]);

  useEffect(() => {
    if (window?.innerWidth < 769) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    if (urlParams.fontSize != undefined) {
      setTextAreaFontSize(JSON.parse(urlParams.fontSize));
    }
  }, [urlParams, searchParams]);

  useEffect(() => {
    if (variation != 2) {
      colorData.map((x: any, i: any) => {
        if (urlParams?.color?.includes(i + 1)) {
          setTextAreaColor(x.color);
        }
      });
    } else {
      setTextAreaColor("transparent_text");
    }
  }, [urlParams, variation, searchParams]);

  useEffect(() => {
    if (textAreaColor == "transparent_text") {
      setTextAreaColor("dark");
    }
  }, [variation, textAreaColor]);

  useEffect(() => {
      colorData.map((x: any, i: any) => {
        if (urlParams?.color?.includes(i + 1)) {
          setTextAreaColor(x.color);
        }
      });
  }, [urlParams, variation, searchParams]);
  if (!event) return <Loading />;

  const color = () => {
    var currentColor: any = "";
    if (textAreaColor == "light")
      currentColor = {
        backgroundColor: "white",
        // overflowY: "hidden",
        color: "black",
        border: "0px solid black",
      };

    if (textAreaColor == "light_text_background")
      currentColor = {
        backgroundColor: "black",
        color: "white",
      };
    if (textAreaColor == "dark")
      currentColor = {
        backgroundColor: "black",
        color: "white",
      };
    if (textAreaColor == "green")
      currentColor = {
        backgroundColor: "rgb(0, 255, 0)",
        color: "white",
        border: "0px solid black",
      };
    if (textAreaColor == "green_black")
      currentColor = {
        backgroundColor: "rgb(0, 255, 0)",
        color: "black",
        border: "0px solid black",
      };
    if (textAreaColor == "blue")
      currentColor = {
        backgroundColor: "rgb(0, 51, 204)",
        color: "white",
        border: "0px solid black",
      };
    if (textAreaColor == "blue_black")
      currentColor = {
        backgroundColor: "rgb(0, 51, 204)",
        color: "black",
        border: "0px solid black",
      };
    if (textAreaColor == "transparent_text")
      currentColor = {
        backgroundColor: "rgb(0,0,0,.5)",
        color: "white",
        border: "0px solid black",
        overflowY: "hidden",
        textShadow: "2px 2px #000000",
      };
    if (textAreaColor == "transparent")
      currentColor = {
        backgroundColor: "transparent",
        color: "white",
        border: "0px solid black",
        overflowY: "hidden",
        textShadow: "2px 2px #000000",
      };
    return currentColor;
  };

  return (
    <>
      <Group align="top" className={`${classes.main_container} `}>
        <div ref={ref} className={`${classes.editor_main_area}`}>
          <div className={`${classes.editor_container} `}>
            <div className={`${classes.editor_text_area} `}>
              <div
                className={`${classes.editor_text}
             `}
              >
                {event?.isRichText && (
                  <RichTextEditor
                    id={event.uid}
                    downloadWorld={downloadWorld}
                    setDownloadDocx={setDownloadDocx}
                    readOnly={true}
                    collection={"richtexts"}
                    fontSize={textAreaFontSize + "px"}
                    autoScroll={true}
                  />
                )}
                {!showTranslation &&
                  !currentTranslation &&
                  !event.isRichText && (
                    <TextAreaEditor
                      readonly={true}
                      event={event}
                      mobile={mobile}
                      showVideo={showVideo}
                      variation={variation}
                      divideIntoTwo={divideIntoTwo}
                      textCenter={false}
                      autoScroll={true}
                      scrollHidden={true}
                      fontSize={textAreaFontSize}
                      color={color()}
                    />
                  )}
                {urlParams.delay == undefined || urlParams.delay == "0"
                  ? showTranslation &&
                    currentTranslation === "en" && (
                      <OnlyTextTranslationArea
                        variation={variation}
                        event={event}
                        autoScroll={true}
                        textCenter={
                          variation != 1 &&
                          !divideIntoTwo &&
                          !textFullSize &&
                          true
                        }
                        mobile={mobile}
                        fontSize={textAreaFontSize}
                        language={currentTranslation}
                        color={color()}
                      />
                    )
                  : showTranslation &&
                    currentTranslation === "en" && (
                      <TranslationReplayArea
                        variation={variation}
                        event={event}
                        mobile={mobile}
                        delay={urlParams.delay}
                        textCenter={
                          !textFullSize &&
                          variation != 1 &&
                          !divideIntoTwo &&
                          true
                        }
                        autoScroll={true}
                        fontSize={textAreaFontSize}
                        language={currentTranslation}
                        color={color()}
                      />
                    )}

                {urlParams.delay == undefined || urlParams.delay == "0"
                  ? showTranslation &&
                    currentTranslation === "de" && (
                      <OnlyTextTranslationArea
                        variation={variation}
                        event={event}
                        autoScroll={true}
                        textCenter={
                          variation != 1 &&
                          !divideIntoTwo &&
                          !textFullSize &&
                          true
                        }
                        mobile={mobile}
                        fontSize={textAreaFontSize}
                        language={currentTranslation}
                        color={color()}
                      />
                    )
                  : showTranslation &&
                    currentTranslation === "de" && (
                      <TranslationReplayArea
                        variation={variation}
                        event={event}
                        mobile={mobile}
                        delay={urlParams.delay}
                        textCenter={
                          !textFullSize &&
                          variation != 1 &&
                          !divideIntoTwo &&
                          true
                        }
                        autoScroll={true}
                        fontSize={textAreaFontSize}
                        language={currentTranslation}
                        color={color()}
                      />
                    )}
                {urlParams.delay == undefined || urlParams.delay == "0"
                  ? showTranslation &&
                    currentTranslation === "pre" && (
                      <OnlyTextTranslationArea
                        variation={variation}
                        event={event}
                        autoScroll={true}
                        textCenter={
                          variation != 1 &&
                          !divideIntoTwo &&
                          !textFullSize &&
                          true
                        }
                        mobile={mobile}
                        fontSize={textAreaFontSize}
                        language={"preprocessed"}
                        color={color()}
                      />
                    )
                  : showTranslation &&
                    currentTranslation === "pre" && (
                      <TranslationReplayArea
                        variation={variation}
                        event={event}
                        mobile={mobile}
                        delay={urlParams.delay}
                        textCenter={
                          !textFullSize &&
                          variation != 1 &&
                          !divideIntoTwo &&
                          true
                        }
                        autoScroll={true}
                        fontSize={textAreaFontSize}
                        language={"pre"}
                        color={color()}
                      />
                    )}

                {urlParams.delay == undefined || urlParams.delay == "0"
                  ? showTranslation &&
                    currentTranslation === "rm" && (
                      <OnlyTextTranslationArea
                        variation={variation}
                        event={event}
                        autoScroll={true}
                        textCenter={
                          variation != 1 &&
                          !divideIntoTwo &&
                          !textFullSize &&
                          true
                        }
                        mobile={mobile}
                        fontSize={textAreaFontSize}
                        language={currentTranslation}
                        color={color()}
                      />
                    )
                  : showTranslation &&
                    currentTranslation === "rm" && (
                      <TranslationReplayArea
                        variation={variation}
                        event={event}
                        mobile={mobile}
                        delay={urlParams.delay}
                        textCenter={
                          !textFullSize &&
                          variation != 1 &&
                          !divideIntoTwo &&
                          true
                        }
                        autoScroll={true}
                        fontSize={textAreaFontSize}
                        language={currentTranslation}
                        color={color()}
                      />
                    )}
                {urlParams.delay == undefined || urlParams.delay == "0"
                  ? showTranslation &&
                    currentTranslation === "uk" && (
                      <OnlyTextTranslationArea
                        variation={variation}
                        event={event}
                        autoScroll={true}
                        textCenter={
                          variation != 1 &&
                          !divideIntoTwo &&
                          !textFullSize &&
                          true
                        }
                        mobile={mobile}
                        fontSize={textAreaFontSize}
                        language={currentTranslation}
                        color={color()}
                      />
                    )
                  : showTranslation &&
                    currentTranslation === "uk" && (
                      <TranslationReplayArea
                        variation={variation}
                        event={event}
                        mobile={mobile}
                        delay={urlParams.delay}
                        textCenter={
                          !textFullSize &&
                          variation != 1 &&
                          !divideIntoTwo &&
                          true
                        }
                        autoScroll={true}
                        fontSize={textAreaFontSize}
                        language={currentTranslation}
                        color={color()}
                      />
                    )}
                {urlParams.delay == undefined || urlParams.delay == "0"
                  ? showTranslation &&
                    currentTranslation === "fr" && (
                      <OnlyTextTranslationArea
                        variation={variation}
                        event={event}
                        autoScroll={true}
                        textCenter={
                          variation != 1 &&
                          !divideIntoTwo &&
                          !textFullSize &&
                          true
                        }
                        mobile={mobile}
                        fontSize={textAreaFontSize}
                        language={currentTranslation}
                        color={color()}
                      />
                    )
                  : showTranslation &&
                    currentTranslation === "fr" && (
                      <TranslationReplayArea
                        variation={variation}
                        event={event}
                        mobile={mobile}
                        delay={urlParams.delay}
                        textCenter={
                          !textFullSize &&
                          variation != 1 &&
                          !divideIntoTwo &&
                          true
                        }
                        autoScroll={true}
                        fontSize={textAreaFontSize}
                        language={currentTranslation}
                        color={color()}
                      />
                    )}
                {urlParams.delay == undefined || urlParams.delay == "0"
                  ? showTranslation &&
                    currentTranslation === "it" && (
                      <OnlyTextTranslationArea
                        variation={variation}
                        event={event}
                        autoScroll={true}
                        textCenter={
                          variation != 1 &&
                          !divideIntoTwo &&
                          !textFullSize &&
                          true
                        }
                        mobile={mobile}
                        fontSize={textAreaFontSize}
                        language={currentTranslation}
                        color={color()}
                      />
                    )
                  : showTranslation &&
                    currentTranslation === "it" && (
                      <TranslationReplayArea
                        variation={variation}
                        event={event}
                        mobile={mobile}
                        delay={urlParams.delay}
                        textCenter={
                          !textFullSize &&
                          variation != 1 &&
                          !divideIntoTwo &&
                          true
                        }
                        autoScroll={true}
                        fontSize={textAreaFontSize}
                        language={currentTranslation}
                        color={color()}
                      />
                    )}
              </div>
            </div>
          </div>
        </div>
      </Group>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  main_container: {
    height: "100%",
    position: "relative",
  },
  editor_text_main_area: {
    height: "70%",
    width: "100%",
  },
  two_text_editor: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  editor_main_area: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    paddingTop: 0,
    alignItems: "center",
    "@media (max-width: 768px)": {
      width: "100% !important",
      paddingTop: 0,
    },
  },

  editor_container: {
    width: "49%",
    height: "100%",
    "@media (max-width: 1450px)": {
      width: "80% ",
    },
    "@media (max-width: 550px)": {
      width: "100% ",
    },
  },
  editor_text: {
    height: "48%",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  editor_text_area: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
}));
export default PublicInterpreterOnlyText;
