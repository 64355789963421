import React, { useEffect, useState, useRef } from "react";
import { Text, Paper } from "@mantine/core";
import { Scrollbar } from "react-scrollbars-custom";
import { useConnector } from "../../../hooks/sharedbconnector.hook";
import { useSearchParams } from "react-router-dom";

interface OnlyTextTranslationAreaProps {
  event: any;
  autoScroll: boolean;
  textCenter: boolean;
  fontSize: number;
  mobile: boolean;
  language: string;
  variation: number;
  color: { backgroundColor: string; color: string };
}
const OnlyTextTranslationArea: React.FC<OnlyTextTranslationAreaProps> = ({
  event,
  autoScroll,
  textCenter,
  mobile,
  fontSize,
  language,
  variation,
  color,
}) => {
  const refContainer = useRef<HTMLDivElement | null>(null);
  const [connected, setConnected] = useState(false);
  const [searchParams] = useSearchParams();
  const urlParams = Object.fromEntries([...searchParams]);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const [totalHeight, setTotalHeight] = useState(0);
  const [remainingHeight, setRemainingHeight] = useState(0);
  const [scrollControl, setScrollControl] = useState(false);
  const [queue, setQueue] = useState<any[]>([]);
  const intervalRef: any = useRef<NodeJS.Timeout | null>(null);
  const speed: number = urlParams?.speed ? parseInt(urlParams.speed, 10) : 20;

  const [
    bindingReady,
    guid,
    operationCounter,
    translatedData,
    connect,
    { init, close },
  ] = useConnector();
  const scrollbarRef = useRef<any>(null);

  useEffect(() => {
    if (connected) return;
    if (init && event && !event.isRichText) {
      setConnected(true);
      init(event.id, language, language, refContainer.current);
    }
  }, [event, language, connected, init]);

  useEffect(() => {
    return () => {
      close();
    };
  }, [close]);

  useEffect(() => {
    if (scrollbarRef.current && translatedData.length > 0 && !scrollControl) {
      const allDivs =
        scrollbarRef.current?.contentElement.querySelectorAll("div > div");
      const filteredDivs = Array.from(allDivs)
        .map((parentDiv: any) => parentDiv.querySelector("div"))
        .filter(
          (div) => div && div.textContent && div.textContent.trim() !== ""
        );
      const _totalHeight = filteredDivs.reduce(
        (sum, div) => sum + (div ? div.getBoundingClientRect().height : 0),
        0
      );

      setTotalHeight(_totalHeight);
      setTimeout(() => {
        scrollbarRef.current.scrollTop = _totalHeight;
      }, 0);
      setScrollControl(true);
    }
    if (scrollbarRef.current && translatedData.length == 0 && connect) {
      setScrollControl(true);
    }
  }, [scrollbarRef, translatedData, scrollControl, connect]);

  useEffect(() => {
    if (scrollControl) {
      const allDivs =
        scrollbarRef.current?.contentElement.querySelectorAll("div > div");
        const filteredDivs = Array.from(allDivs)
        .map((parentDiv: any) => parentDiv.querySelector("div"))
        .filter(
          (div) => div && div.textContent && div.textContent.trim() !== ""
        );
      const _totalHeight = filteredDivs.reduce(
        (sum, div) => sum + (div ? div.getBoundingClientRect().height : 0),
        0
      );
      setTotalHeight(_totalHeight);
      if (_totalHeight > totalHeight) {
        setQueue((prevQueue) => [...prevQueue, _totalHeight]);
      }
    }
  }, [
    scrollControl,
    totalHeight,
    scrollbarRef,
    translatedData,
    operationCounter,
  ]);

  const smoothScrollIncremental = (
    targetScrollTop: any,
    callback: () => void
  ) => {
    const contentElement = scrollbarRef.current;
    if (!contentElement) return;

    const step = 1; // Her adımda scrollTop'u ne kadar artıracağımız
    const interval = speed; // Her artış arasındaki süre (speed'e bağlı olarak ayarlanır)

    // Daha önce başlatılmış olan bir scroll interval varsa temizle
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      const currentScrollTop = contentElement.scrollTop;

      if (currentScrollTop < targetScrollTop) {
        contentElement.scrollTop = Math.min(
          currentScrollTop + step,
          targetScrollTop
        );
      } else {
        clearInterval(intervalRef.current);
        callback(); // Scroll tamamlandığında callback'i çağır
      }
    }, interval);
  };
  useEffect(() => {
    if (queue.length > 0 && remainingHeight === 0) {
      processQueue();
    }
  }, [queue, remainingHeight, translatedData, operationCounter]);

  const processQueue = () => {
    if (queue.length === 0) return;

    const nextHeight = queue[0];
    setQueue((prevQueue) => prevQueue.slice(1));

    const difference = nextHeight - totalHeight;
    setRemainingHeight(difference);

    const updatedTargetScrollTop = nextHeight; 


    smoothScrollIncremental(updatedTargetScrollTop, () => {
      setRemainingHeight(0); // Animasyon bittiğinde remainingHeight sıfırlanır
      if (queue.length > 0) {
        processQueue(); // Bir sonraki elemanı işle
      }
    });
  };

  const renderTranslations = (trans: any[]) => {
    return trans.map((sentenceData, index) => {
      const isNew = index === translatedData.length - 1;

      return (
        <div
          key={index}
          ref={isNew ? lastElementRef : null}
          style={
            color.backgroundColor !== "transparent"
              ? { textAlign: "left" }
              : { textAlign: "center" }
          }
        >
          <Text
            style={{
              fontSize: `${fontSize}px `,
              wordBreak: "break-word",
              textAlign: textCenter ? "center" : undefined,
              lineHeight:
                variation === 2
                  ? fontSize === 39
                    ? "1.6"
                    : fontSize === 16
                    ? "1.55"
                    : fontSize === 26
                    ? "1.55"
                    : "1.5"
                  : "1.5",
            }}
          >
            {sentenceData.value}
          </Text>
        </div>
      );
    });
  };

  return (
    <Paper
      ref={(el: any) => (componentRef.current = el)}
      radius={0}
      style={{
        ...color,
        outline: "none",
        fontSize: `${fontSize}px `,
        width: "100%",
        height: "100%",
        padding:
          variation === 2 ? "0px 70px" : variation === 3 ? "16px 70px" : 16,
        resize: "none",
        lineHeight:
          variation === 2
            ? fontSize === 39
              ? "1.6"
              : fontSize === 16
              ? "1.55"
              : fontSize === 26
              ? "1.55"
              : "1.5"
            : "1.5",
      }}
    >
      <Scrollbar
        noScrollY={true}
        style={{
          width: "100%",
          height: "100%",
        }}
        ref={scrollbarRef}
        tabIndex={0}
      >
        <div style={{height:scrollbarRef?.current?.scrollValues?.clientHeight}}></div>
        {translatedData &&
          translatedData.length > 0 &&
          renderTranslations(translatedData)}
      </Scrollbar>
    </Paper>
  );
};

export default OnlyTextTranslationArea;
