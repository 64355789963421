import AgoraRTC from "agora-rtc-sdk-ng";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import useSignLanguage from "../../../hooks/sign-language.hooks";
import { Button, createStyles } from "@mantine/core";
import { sign } from "crypto";
import SignLanguageVideoRoomPublic from "./SignLanguageVideoRoomPublic";

const SignLanguagePublic = ({
  event,
  showSignLanguage,
  languagePosition,
}: any) => {
  const { classes } = useStyles();
  const [currentDocId, setCurrentDocId]: any = useState<null | string>(null);
  const [firstLanguage, setSignFirstLanguage] = useState("");
  const [lastLanguage, setSignLastLanguage] = useState("");

  const {
    init,
    join,
    leave,
    firebasePublicGetData,
    connecting,
    joinState,
    remoteUsers,
    clientInitialized,
    signLanguageState,
  } = useSignLanguage(currentDocId);

  useEffect(() => {
    if (event) {
      init();
      setCurrentDocId(event.id);
    }
  }, [event, showSignLanguage]);

  useEffect(() => {
    if (event) {
      firebasePublicGetData(event.id);
    }
  }, [event, joinState, remoteUsers, showSignLanguage]);

  useEffect(() => {
    if (clientInitialized) {
      join(event.id, "audience");
    }
  }, [clientInitialized, showSignLanguage]);

  // useEffect(() => {
  //   if(signLanguageState?.liveStream){
  //     join(event.id, "audience");
  //   }else{
  //     leave();
  //   }
  // },[signLanguageState])

  useEffect(() => {
    if (remoteUsers.length > 0 && remoteUsers != undefined) {
      if (joinState) {
        if (remoteUsers.length >= 1) {
          const mainVideo = remoteUsers.find(
            (x: any) => x.uid == signLanguageState.mainId
          );

          if (mainVideo?.videoTrack != undefined) {
            setSignFirstLanguage(mainVideo.uid);
            mainVideo?.videoTrack.play("publicvideo1");
          }
        }
        if (remoteUsers?.length == 2) {
          const sideVideo = remoteUsers.find(
            (x: any) => x.uid != signLanguageState.mainId
          );
          if (sideVideo?.videoTrack != undefined) {
            setSignLastLanguage(sideVideo.uid);
            sideVideo?.videoTrack.play("publicvideo2");
          }
        }
      }
    }
  }, [connecting, signLanguageState, remoteUsers, joinState, showSignLanguage]);

  return (
    <div
      className={classes.main}
      style={{ margin: languagePosition == 1 ? 0 : 20 }}
    >
      {/* <p style={{color:"white"}}>
      
      {firstLanguage}
      </p>  */}
      <div id="publicvideo1" className={classes.main_video}></div>
      {/* <p style={{color:"white"}}>
      
      {lastLanguage}
      </p>  */}
      <div id="publicvideo2" style={{ display: "none" }}></div>
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  main: {
    display: "flex",
  },
  main_video: {
    width: "12vw",
    height: "12vw",

    "@media (max-width: 1450px)": {
      width: "16vw",
      height: "16vw",
    },
    "@media (max-width: 987px)": {
      width: "23vw",
      height: "23vw",
    },
    "@media (max-width: 600px)": {
      width: "33vw",
      height: "33vw",
    },
  },
}));
export default SignLanguagePublic;
