import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Timeline,
  Text,
  Paper,
  TextInput,
  createStyles,
  Box,
  Textarea,
} from "@mantine/core";
import { Scrollbar } from "react-scrollbars-custom";

import Loading from "../loading";
import { useConnector } from "../../../hooks/sharedbconnector.hook";
import { useLocalStorage } from "@mantine/hooks";

import { IUpdateTranslationRequest } from "../../../interfaces/update-translation-request.interface";
import { openModal, closeAllModals } from "@mantine/modals";
import EditBox from "./editBox";
import axios from "axios";

const TranslationAreaEditor = ({
  event,
  autoScroll,
  fontSize,
  language,
}: any) => {
  const refContainer: any = useRef();
  const [connected, setConnected] = useState(false);
  const [position, setPosition] = useState(-1);
  const [arrowInput, setArrowInput] = useState(false);
  const [editor, setEditor] = useState(false);
  const [updateText, setUpdateText] = useState(false);
  const [newText, setNewText] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [saveText, setSaveText] = useState(false);
  const [inputMode, setInputMode] = useState(false);

  const [textLength, setTextLength] = useState(0);

  const [selectInputData, setSelectInputData]: any = useState([]);
  const { classes } = useStyles();

  const [currentSelectedTextValue, setCurrentSelectedTextValue] = useState("");
  const [currentSelectedSentence, setCurrentSelectedSentence] = useState<
    null | any
  >(null);
  const inputRefs: any = useRef([]);

  const [
    bindingReady,
    guid,
    operationCounter,
    translatedData,
    connect,
    { init, close },
  ] = useConnector();
  const [currentIndex, setCurrentIndex] = useState(0);

  const [translateData, setTranslateData]: any = useState([]);
  const scrollbarRef: any = useRef<HTMLDivElement>();
  const firstWordShortcut: any = localStorage.getItem("first_word_shortcut");
  const lastWordShortcut: any = localStorage.getItem("last_word_shortcut");
  const firstSentenceShortcut = localStorage.getItem("first_sentence_shortcut");
  const lastSentenceShortcut = localStorage.getItem("last_sentence_shortcut");
  const inputModeShortcut = localStorage.getItem("input_mode");

  useEffect(() => {
    setTranslateData(translatedData);
  }, [
    translatedData,
    editor,
    updateText,
    newText,
    selectInputData,
    deleteItem,
  ]);

  useEffect(() => {
    setCurrentIndex(translatedData.length - 1);
  }, [translatedData]);

  useEffect(() => {
    inputSelect();
  }, [currentIndex]);
  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, translatedData.length);
  }, []);

  useEffect(() => {
    if (connected) return;

    if (init && event && !event.isRichText) {
      setConnected(true);
      init(event.id, language, language, refContainer.current);
    }
  }, [event, language, connected, init]);

  useEffect(() => {
    if (autoScroll && scrollbarRef.current) {
      scrollToBottom();
    }
  }, [scrollbarRef, autoScroll]);

  useEffect(() => {
    return () => {
      close();
    };
  }, []);

  useEffect(() => {
    if (autoScroll) {
      setTimeout(() => {
        scrollToBottom();
      }, 1000);
    }
    if (autoScroll) {
      setTimeout(() => {
        scrollToBottom();
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (autoScroll) {
      scrollToBottom();
    }
  }, []);

  const handleKeywordUpdate = (
    index: number,
    i: number,
    value: string,
    hash: number
  ) => {
    if (
      selectInputData[i] != value &&
      selectInputData[i] != " " &&
      value.length != 0
    ) {
      selectInputData[i] = value;
    }
    if (selectInputData[i] != value) {
      if (value.length != 0) {
        selectInputData.splice(i, 0, value);
      } else {
        selectInputData.splice(i, 1);
      }
    }

    handleUpdate(index, selectInputData.join(``), hash);
    setNewText(false);
    setUpdateText(true);
  };
  const handleUpdate = async (index: number, value: string, hash: number) => {
    // /update-translation
    // closeAllModals();
    const data: IUpdateTranslationRequest = {
      roomId: event.id,
      index: index,
      value: value,
      hash: Number(hash),
    };
    setCurrentSelectedTextValue("");
    setCurrentSelectedSentence(null);

    /* let endPoint =
      "https://translate-controller-" +
      language +
      ".dev.liveaccess.net/update-translation";
      */

    let endPoint =
      "https://translate-controller-" +
      language +
      "-passive.dev.liveaccess.net/update-translation";

    if (language === "preprocessed") {
      endPoint =
        "https://preprocessing.dev.liveaccess.net/update-translation";
    }

    // https://prod-translate-controller-de-passive.madarray.solutions
    const config = {
      headers: {
        Authorization: `Bearer ${"api-123"}`,
      },
    };
    try {
      await axios.post(endPoint, data, config);
      setUpdateText(false);
      setSaveText(true);
    } catch (err) {
      console.log("zxcerr", err);
    }
  };

  const inputSelect = () => {
    const _text: any = translateData[currentIndex]?.value.split(/(\s+)/);
    setSelectInputData(_text);
  };

  useEffect(() => {
    if (saveText) {
      setTimeout(() => setSaveText(false), 1000);
    }
  }, [saveText]);
  const handleUserKeyPress = useCallback(
    (event: any) => {
      const { keyCode } = event;
      setDeleteItem(false);

      if (keyCode == 13) {
        setEditor(true);
        if (editor) {
          setEditor(false);
        } else {
          setNewText(false);
        }
      }
      if (keyCode == 27) {
        if (editor) {
          setNewText(false);
          setEditor(false);
        } else {
          setNewText(false);
          setEditor(false);
          setArrowInput(false);
        }
        if (inputMode) {
          setInputMode(false);
        }
      }

      if (!editor && event.ctrlKey && event.key == firstWordShortcut) {
        setPosition(0);
      }
      if (!editor && event.ctrlKey && event.key == lastWordShortcut) {
        let selectInputDataLength = selectInputData.length - 1;
        setPosition(selectInputDataLength);
      }
      if (!editor && event.ctrlKey && event.key == lastSentenceShortcut) {
        var last = translateData.length - 1;
        inputRefs.current[last].focus();
        setCurrentIndex(last);
      }
      if (!editor && event.ctrlKey && event.key == firstSentenceShortcut) {
        inputRefs.current[0].focus();
        setCurrentIndex(0);
      }
      if (event.ctrlKey && event.key == inputModeShortcut) {
        setInputMode(true);
      }
      if (event.shiftKey && keyCode == 37 && position != 0) {
        setEditor(true);
        if (position == -1) {
          setPosition(0);
        } else {
          setPosition(position - 1);
        }
      }

      if (event.shiftKey && keyCode == 39) {
        setEditor(true);
        if (position <= selectInputData.length - 2) setPosition(position + 1);
      }
      if (!editor && event.keyCode == 38) {
        setPosition(-1);
        inputRefs.current[currentIndex - 1].focus();
        inputSelect();
        setCurrentIndex(currentIndex - 1);
      }
      if (!editor && event.keyCode == 40) {
        setPosition(-1);
        inputRefs.current[currentIndex + 1].focus();
        inputSelect();
        setCurrentIndex(currentIndex + 1);
      }
      if (event.keyCode == 46 && arrowInput) {
        selectInputData.splice(position, 1);
        handleUpdate(
          position,
          selectInputData.join(``),
          translateData[currentIndex].hash
        );
        setDeleteItem(true);
      }
    },
    [position, editor, currentIndex, arrowInput, newText, inputMode]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [inputMode, position, editor, currentIndex, arrowInput, newText]);

  const onKeyDown = useCallback(
    (i: number, event: any) => {
      if (!editor && event.keyCode == 38) {
        inputRefs.current[i - 1].focus();
        inputSelect();
        setCurrentIndex(i - 1);
      }
      if (!editor && event.keyCode == 40) {
        inputRefs.current[i + 1].focus();
        inputSelect();
        setCurrentIndex(i + 1);
      }
    },
    [editor]
  );
  const selectInput = () => {
    setPosition(-1);
    setArrowInput(false);
  };

  const renderTranslations = (trans: any) => {
    return trans.map((sentenceData: any, index: number) => {
      return (
        <Timeline.Item
          onClick={() => selectInput()}
          key={index}
          bullet={<Text>{index + 1}</Text>}
          title={""}
          style={{ cursor: "pointer" }}
        >
          <Paper
            style={{
              backgroundColor: currentIndex == index ? "#D3D3D3" : "white",
              border:
                currentIndex == index && saveText
                  ? "1px solid green"
                  : "0px solid ",
              overflow: "hidden",
            }}
            shadow="xs"
            radius="xl"
            pl="md"
            pr="md"
            m="sm"
          >
            {currentIndex == index ? (
              inputMode ? (
                <Textarea
                  ref={(el) => (inputRefs.current[index] = el)}
                  onChange={(e: any) =>
                    handleUpdate(index, e.target.value, sentenceData.hash)
                  }
                  autosize
                  styles={{
                    input: {
                      fontSize: fontSize,
                      border: 0,
                    },
                  }}
                  style={{ padding: "10px 0px" }}
                  defaultValue={sentenceData.value.replace(/\r?\n|\r/g, "")}
                />
              ) : (
                <div
                  style={{
                    padding: "20px 12px",
                  }}
                >
                  {selectInputData?.map((x: any, i: number) => (
                    <div
                      key={i}
                      id="textArea"
                      style={{
                        display: "initial",
                        lineHeight: 1.55,
                      }}
                    >
                      {editor && i == position ? (
                        <input
                          autoFocus={true}
                          onFocus={(e: any) =>
                            setTextLength(e.target.value.length)
                          }
                          onKeyDown={(e: any) => {
                            setTextLength(e.target.value.length);
                            onKeyDown(index, e);
                            if (e.keyCode == 13) {
                              setNewText(true);
                              handleKeywordUpdate(
                                index,
                                i,
                                e.target.value,
                                sentenceData.hash
                              );
                            }
                          }}
                          className={classes.input}
                          style={{
                            fontSize: fontSize,
                            height: "100%",
                            padding: "0px 5px",
                            outline: 0,
                            width: textLength + 1 + "ch",
                            backgroundColor: "#c7adad",
                            borderWidth: 0,
                          }}
                          defaultValue={x}
                        />
                      ) : (
                        <>
                          {x == " " ? (
                            <span> &nbsp;</span>
                          ) : (
                            <span style={{ fontSize: fontSize }}>{x}</span>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )
            ) : (
              <Textarea
                ref={(el) => (inputRefs.current[index] = el)}
                onKeyDown={(e) => onKeyDown(index, e)}
                readOnly
                autosize
                onClick={(e: any) => setCurrentIndex(index)}
                styles={{
                  input: {
                    border: 0,
                    fontSize: fontSize,
                    backgroundColor: currentIndex == index ? "red" : "white",
                  },
                }}
                style={{ padding: "10px 0px" }}
                defaultValue={sentenceData.value.replace(/\r?\n|\r/g, "")}
              />
            )}
            {/* <Text size={fontSize}>{sentenceData.value}</Text> */}
          </Paper>
        </Timeline.Item>
      );
    });
  };

  const scrollToBottom = () => {
    scrollbarRef.current.scrollToBottom({ behavior: "smooth" });
  };

  const onClickEdit = ({
    index,
    value,
    hash,
  }: {
    index: number;
    value: string;
    hash: number;
  }) => {
    const position = index + 1;
    openModal({
      title: "change text on position: " + position,
      children: (
        <EditBox
          index={index}
          value={value}
          hash={hash}
          handleUpdate={handleUpdate}
        />
      ),
    });
  };

  const textareaStyle = {
    outline: "none",
    fontSize: fontSize ? `${fontSize}px` : "24px",
    height: "100%",
    width: "100%",
    backgroundColor: "white",
  };

  return (
    <Paper p="md" radius={0} style={textareaStyle}>
      <Scrollbar style={{ width: "100%", height: "100%" }} ref={scrollbarRef}>
        <Timeline active={-1} bulletSize={32} lineWidth={2}>
          {translateData &&
            translateData.length > 0 &&
            renderTranslations(translateData)}
        </Timeline>
      </Scrollbar>
    </Paper>
  );
};
const useStyles = createStyles((theme) => ({
  input: {
    "*:focus": {
      outline: "none",
    },
  },
}));
export default TranslationAreaEditor;
